import React from "react";
import "./Footer.css";
import logo from "../assets/logo2.svg";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__top">
        <div className="footer__left">
          <img src={logo} alt="Logo Ororr" className="footer__logo" />
        </div>
        <div className="footer__right">
          <div className="footer__contact">
            <div>
              <p>
                <strong>Adresse :</strong>
              </p>
              <p>Paris, France</p>
            </div>
            <div className="footer__contact_2">
              <p>
                <strong>Contact :</strong>
              </p>
              <p><a href="mailto:contact@ororr.fr">contact@ororr.fr</a></p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <div className="footer__links">
          <a href="/">&copy;Ororr</a>
          <a href="/mentionslegales">Mentions légales</a>
          <a href="/cgv">CGV</a>
          <a href="/rgpd">Politique de confidentialité</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
