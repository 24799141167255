import React from 'react';
import { useNavigate } from 'react-router-dom';  // import the useNavigate hook
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import './cgv.css';
import Button from '../components/Button';
import Footer from '../components/Footer';

const firebaseConfig = {
    apiKey: "AIzaSyBwZjG3moLZt0rdT19RgvOEZHchXGlzEQo",
    authDomain: "ororr-dc0ac.firebaseapp.com",
    projectId: "ororr-dc0ac",
    storageBucket: "ororr-dc0ac.appspot.com",
    messagingSenderId: "950962781892",
    appId: "1:950962781892:web:a54f876c1bc65cf6d69391"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const Cgv = () => {
  const [cgvArticles, setCgvArticles] = React.useState([]);
  const navigate = useNavigate();  // initialize the useNavigate hook

  const handleBack = () => {  // Add a new handleBack function
    navigate('/');  // this will redirect the user to the home page
  };

  React.useEffect(() => {
    const fetchCgv = async () => {
      const cgvCollection = collection(db, "CGV");
      const cgvSnapshot = await getDocs(cgvCollection);
      let cgvList = cgvSnapshot.docs.map(doc => doc.data());
      cgvList = cgvList.sort((a, b) => {
        const articleNumberA = parseInt(a.article.match(/\d+/)[0]);
        const articleNumberB = parseInt(b.article.match(/\d+/)[0]);
        return articleNumberA - articleNumberB;
      });
      setCgvArticles(cgvList);
    };

    fetchCgv();
  }, []);

  return (
    <div>
      <div className='head_y'>
        <h1 className='TitreDivCgv'>Conditions Générales de services</h1>
        <Button onClick={handleBack}>Retour</Button>
      </div>
      {cgvArticles.map((cgvArticle, index) => (
        <div key={index} className='cgv-div'>
          <h2 className='cgv-title'>{cgvArticle.article}</h2>
          <p>{cgvArticle.text}</p>
        </div>
      ))}
      <div className='Retour'>
        <Button onClick={handleBack}>Retour</Button>
      </div>
     
      <Footer></Footer>
    </div>
  );
};

export default Cgv;
