import React from "react";
import { useNavigate } from "react-router-dom";
import "./cgv.css";
import Button from "../components/Button";
import Footer from "../components/Footer";

const Rgpd = () => {
  const navigate = useNavigate(); // initialize the useNavigate hook

  const handleBack = () => {
    // Add a new handleBack function
    navigate("/"); // this will redirect the user to the home page
  };

  return (
    <div>
      <div className="head_y">
        <h1 className="TitreDivCgv">Charte sur le respect de la vie privée</h1>
        <Button onClick={handleBack}>Retour</Button>
      </div>
      <div className="cgv-div">
        <p>
        La présente Charte sur le respect de la vie privée (la "Charte") a pour objectif de formaliser notre engagement quant au respect de la vie privée des utilisateurs du site internet www.ororr.fr (le "Site"), exploité par ORROR.
        </p>
        <br></br>
        <p>
        Dans le cadre de la mise à disposition de notre site, nous traitons vos données à caractère personnel dans le respect du Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016 (RGPD) et dans les conditions exposées ci-après. Une donnée à caractère personnel désigne toute information se rapportant à une personne physique identifiée ou identifiable.
        </p>
        <br></br>
        <p>
        Nous sommes susceptibles de recueillir et de conserver vos données à caractère personnel uniquement lorsque vous nous contactez via le formulaire de contact sur notre site. Les données collectées incluent votre nom, votre adresse e-mail, et toute autre information que vous nous fournissez volontairement dans le cadre de votre message. Ces données sont utilisées exclusivement pour répondre à votre demande et ne sont pas partagées avec des tiers.
        </p>





      </div>
      <div className="Retour">
        <Button onClick={handleBack}>Retour</Button>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default Rgpd;
