import React from 'react';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const primary = '#BABABA';
const rippleColor = '#E7B98A';

const textFieldTheme = createTheme({
  palette: {
    primary: {
      main: primary,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: rippleColor,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: rippleColor,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          '&.Mui-focused': {
            color: rippleColor,
          },
        },
      },
    },
  },
});

const CustomTextField = (props) => {
  return (
    <ThemeProvider theme={textFieldTheme}>
      <TextField {...props} style={{ width: '100%' }} />
    </ThemeProvider>
  );
};

export default CustomTextField;
