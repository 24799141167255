import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import './cgv.css';
import Button from '../components/Button';
import Footer from '../components/Footer';


const MentionsLegales = () => {
  const navigate = useNavigate();  // initialize the useNavigate hook

  const handleBack = () => {  // Add a new handleBack function
    navigate('/');  // this will redirect the user to the home page
  };

  
  return (
    <div>
      <div className='head_y'>
        <h1 className='TitreDivCgv'>Mentions légales</h1>
        <Button onClick={handleBack}>Retour</Button>
      </div>
        <div  className='cgv-div'>
            <p>Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique, il est précisé aux utilisateurs du site <strong>www.ororr.fr </strong>l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.</p>
          <h2 className='cgv-title'>Édition du site</h2>
          <p>Le présent site, accessible à l'URL <strong>www.ororr.fr</strong> (le "Site"), est édité par ORROR.</p>
          <h2 className='cgv-title'>Hébergement</h2>
          <p>Le site ORROR est hébergé par <strong>Firebase</strong>, un service de Google LLC, dont le siège social est situé à 1600 Amphitheatre Parkway, Mountain View, CA 94043, États-Unis. L'hébergeur est joignable par l'intermédiaire du <a href='https://firebase.google.com/support'>support Firebase</a></p>.
          <h2 className='cgv-title'>Directeur de publication</h2>
          <p>Le directeur de la publication du site est <strong>Thomas Cohen.</strong></p>
          <h2 className='cgv-title'>Nous contacter</h2>
          <p><strong>Par email :</strong> contact@ororr.fr</p>
          <h2 className='cgv-title'>Données personnelles</h2>
          <p>Le traitement de vos données à caractère personnel est régi par notre charte du respect de la vie privée <strong>www.ororr.fr/rgpd</strong>. Conformément au Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016 (RGPD).</p>

        </div>
      <div className='Retour'>
        <Button onClick={handleBack}>Retour</Button>
      </div>
     
      <Footer></Footer>
    </div>
  );
};

export default MentionsLegales;
