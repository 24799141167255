import React from "react";
import "./accueil.css";
import TeamCard from "../components/TeamCard";
import MethodCard from "../components/MethodCard";
import OffreCard from "../components/OffreCard";
import Button from "../components/Button";
import Header from "../components/Header";
import ThomasImage from "../assets/ThomasCohen.png";
import AngeImage from "../assets/AngeEvina.png";
import ContactForm from "../components/Contact";
import Footer from "../components/Footer";

const Accueil = () => {
  const ContactRef = React.useRef(null);
  const OffreRef = React.useRef(null);

  const scrollToContact = () => {
    window.scrollTo({
      top: ContactRef.current.offsetTop - 80,
      behavior: "smooth",
    });
  };

  const scrollToOffre = () => {
    window.scrollTo({
      top: OffreRef.current.offsetTop - 90,
      behavior: "smooth",
    });
  };

  const teamData = [
    {
      name: "Thomas Cohen",
      role: "Co-Fondateur",
      imageUrl: ThomasImage,
      linkedInUrl: "https://www.linkedin.com/in/cohenthomas/", // Remplacez par l'URL correcte
    },
    {
      name: "Ange Evina",
      role: "Co-Fondateur",
      imageUrl: AngeImage,
      linkedInUrl: "https://www.linkedin.com/in/ange-evina", // Remplacez par l'URL correcte
    },
  ];

  const offreData = [
    {
      title: "Automatisation des tâches répétitives",
      text: "Automatiser les tâches routinières. Facturation et comptabilité. Prospection. Identification de vos cas d'utilisation".split(
        "."
      ),
    },
    {
      title: "Conseil. en IA",
      text: "Évaluations de faisabilité. Identification de cas d'utilisation. Formation et sensibilisation. Développement de stratégies".split(
        "."
      ),
    },
    {
      title: "Développement. GPT-3 | GPT 4",
      text: "Synthèse de langage. Apprentissage de vos Connaissances. Développement de Chatbot. Ajustement de modèle".split(
        "."
      ),
    },
    {
      title: "Traitement du Langage Naturel",
      text: "Catégorisation de documents. Exploration de données. Résumé de texte. Identification d'entités".split(
        "."
      ),
    },
  ];

  return (
    <div className="Body">
      <div className="Head">
        <Header
          scrollToContact={scrollToContact}
          scrollToOffre={scrollToOffre}
        ></Header>
        <div className="content-head">
          <h2 className="TitreHead">
            Nous créons des solutions IA et nocode, conçues pour faire évoluer
            votre entreprise.
          </h2>
          <ul>
            <li>
              Gagnez du <span className="highlight">temps</span> et augmentez
              votre <span className="highlight">efficacité</span> grâce à l'
              <span className="highlight">automatisation</span> de tâches.
            </li>
            <li>
              <span className="highlight">Analyse rapide</span> et efficace des{" "}
              <span className="highlight">informations</span> pour prendre les{" "}
              <span className="highlight">meilleures décisions</span>.
            </li>
            <li>
              <span className="highlight">Solutions basées sur l'IA</span>,
              conçues pour <span className="highlight">s'adapter</span> et{" "}
              <span className="highlight">évoluer</span> avec votre entreprise.
            </li>
          </ul>
          <Button onClick={scrollToContact}>Parlons-en</Button>
        </div>
      </div>

      <div className="Offre" ref={OffreRef}>
        <h2 className="TitreDiv">Ce que l’on peut faire pour vous</h2>
        <div className="offre-grid">
          {offreData.map((offre, index) => (
            <OffreCard key={index} title={offre.title} text={offre.text} />
          ))}
        </div>
      </div>
      <div className="Apropos">
        <h2 className="TitreDiv">À Propos</h2>
        <p className="TextPropos">
          ORROR n'existe que depuis deux mois, mais notre vision, elle, est née
          il y a presque un an.
          <br />
          Notre histoire a commencé avec l'arrivée soudaine de l'automatisation
          dans nos vies. Un jour, l'un des partenaires de notre école a
          rencontré un problème d'architecture de l'information.
          <br />
          Nous nous sommes alors lancés, pendant deux mois, dans un projet
          d'automatisation de l'information basé sur l'IA et le no-code.
          <br />
          Grâce à ce travail acharné, le partenaire nous a remerciés et
          récompensés. C'est à ce moment précis que notre passion pour
          l'automatisation intelligente (IA et no-code) est née.
        </p>
      </div>
      <div className="Equipe">
        <h2 className="TitreDiv">Découvrez Notre Équipe</h2>
        <div className="DivEquipe">
          {teamData.map((member) => (
            <TeamCard
              key={member.name}
              name={member.name}
              role={member.role}
              imageUrl={member.imageUrl}
              linkedInUrl={member.linkedInUrl}
            />
          ))}
        </div>
      </div>
      <div className="Methodologie">
        <h2 className="TitreDiv">Notre méthodologie</h2>
        <div className="metho12">
          <MethodCard
            number="1"
            title="Compréhension de vos besoins, objectifs"
            text="Nous commençons par une réunion de découverte où nous nous imprégnons de vos problèmes et objectifs spécifiques. Cela nous permet d'identifier les tâches qui pourraient être automatisées grâce à l'IA, afin d'optimiser votre efficacité."
          />
          <MethodCard
            number="2"
            title="Présentation des possibilités d'automatisation"
            text="Une fois que nous avons une compréhension claire de vos besoins, nous vous présentons une variété de solutions d'automatisation adaptées. Ensemble, nous définissons un plan d'action pour la mise en œuvre de ces solutions."
          />
        </div>

        <div className="metho34">
          <MethodCard
            number="3"
            title="Formation et amélioration de la solution"
            text="Après la mise en place de la solution, nous assurons une formation pour vous aider à comprendre et à utiliser efficacement les nouvelles technologies. Nous restons également disponibles pour apporter toute amélioration nécessaire et pour assurer un support technique continu, afin que vous puissiez tirer le meilleur parti de l'automatisation."
          />
          <MethodCard
            number="4"
            title="Suivi et développement de la solution"
            text="Nous développons les solutions d'automatisation choisies, qu'il s'agisse de chatbots, d'outils basés sur GPT-3 | GPT-4, ou d'autres applications. En cours de développement, nous assurons un suivi régulier pour garantir que la solution répond à vos attentes et pour apporter les ajustements nécessaires."
          />
        </div>
      </div>
      <div className="Contact" ref={ContactRef}>
        <h2 className="TitreDiv">Contact</h2>
        <ContactForm></ContactForm>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Accueil;
