import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Accueil from '../pages/accueil';
import './App.css';
import CgvPage from '../pages/Cgv.js';
import MentionsLegales from '../pages/MentionsLegales';
import Rgpd from '../pages/rgpd';

function App() {
  return (
    <Router basename='/'>
      <Routes>
        <Route path="/" element={<Accueil/>} />
        <Route path="/cgv" element={<CgvPage/>} />
        <Route path="/mentionslegales" element={<MentionsLegales/>} />
        <Route path="/rgpd" element={<Rgpd/>} />
        <Route path="*" element={<Accueil/>} />
      </Routes>
    </Router>
  );
}

export default App;
